import * as React from 'react';
import RouterConfig from './routes/RouterConfig';
import styled from "styled-components";
import Footer from "components/common/Footer";
import Header from "components/common/Header";
import ReactGA from "react-ga4";
import {usePublicKey} from "hooks/usePublicKey";
import { useAddress } from '@thirdweb-dev/react'
import {useCallback, useEffect} from "react";
import { Profile } from '@thelovebridge/core/src/entities'
import { useHistory, useLocation } from 'react-router-dom'
import { v4 } from 'uuid'
import {checkObjectEmpty} from "utils/checkObjectEmpty";
import useAuth from 'hooks/useAuth'
import useScreenOrientation from "hooks/useScreenOrientation";
import {OrientationType} from "models/OrientationType";
import useWindowDimensions from "hooks/useWindowDimensions";

const TRACKING_ID = String(process.env.REACT_APP_NEXT_PUBLIC_GOOGLE_ANALYTICS); // OUR_TRACKING_ID
const nonce = v4()
ReactGA.initialize(TRACKING_ID, {
  testMode: process.env.REACT_APP_URL !== 'thelovebridge.io',
  nonce,
  gaOptions: {
    userId:  "n/a"
  },
});

function App() {
  const {user,isNotProductionEnv} = useAuth();
  const history = useHistory()
  const { pathname, search } = useLocation()
  const {updatePublicKeyFnc} = usePublicKey()
  const address = useAddress()
  const orientation = useScreenOrientation()
  const windowDimensions = useWindowDimensions()
  const {width:windowWidth} = windowDimensions
  useEffect(() => {
    if(!address){
      updatePublicKeyFnc(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[address])

  const trackPageView = useCallback(() => {
    ReactGA.ga("set", "page", pathname + search);
    ReactGA.ga("send", "pageview");
  },[pathname, search])
  // update user id when user is logged in or disconnected

  useEffect(() => {
    ReactGA.set({ userId: (user && !checkObjectEmpty(user)) && (user.data as any as Profile)?.userAddress ? (user.data as any as Profile).userAddress : "n/a", });
  }, [user]);

  // page view event
  useEffect(() => {
    trackPageView(); // To track the first pageview upon load
    history.listen(trackPageView); // To track the subsequent pageviews
  }, [history,trackPageView]);
  useEffect(() => {
    // Twitter conversion tracking base code
    const headNode = document.querySelector<HTMLHeadElement>('head')
    if(!headNode || isNotProductionEnv) return
    const script = document.createElement('script')
    script.type = 'text/script'
    script.async = true
    script.innerHTML = `
    !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
        },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
          a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
          twq('config','okm79');
    `
    headNode.prepend(script)
  },[isNotProductionEnv])
  return (
    <AppWrapper>
      <HeaderWrapper landscape={windowWidth < 1024 && OrientationType.LANDSCAPE === orientation}>
        <Header />
      </HeaderWrapper>
      <RouterConfig />
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${!isNotProductionEnv ? process.env.REACT_APP_NEXT_PUBLIC_GOOGLE_ANALYTICS : undefined}`}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${!isNotProductionEnv ? process.env.REACT_APP_NEXT_PUBLIC_GOOGLE_ANALYTICS : undefined}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
    </AppWrapper>
  )
}

export default App
const AppWrapper = styled.div`
`
const HeaderWrapper = styled.div<{ landscape: boolean }>`
  background-color: ${({theme}) => theme.primaryColor1};
  position: ${({landscape}) => landscape ? 'static' : 'fixed'};
  top: 0;
  left: 0;
  right: 0;
  height: 138px;
  z-index: 10;
  @media only screen and (max-width: 1023px) {
    height: 120px;
  }
  @media only screen and (max-width: 767px) {
    height: 90px;
  }
  @media only screen and (max-width: 575px) {
    height: 140px;
  }
`
const FooterWrapper = styled.div`
  background-color: ${({theme}) => theme.bgColor4};
  padding-top: 100px;
  padding-bottom: 30px;
`
